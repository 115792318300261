/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/06/2023.
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ClinicsMap = ({ scrollToSignup }) => {
  const { t } = useTranslation()

  // new
  return (
    <>
      <div className="row mt-2 mt-md-2">
        <div className="row mt-2 mt-md-7">
          <div
            className="col-md-6 col-12 mx-auto d-flex align-items-center mb-6"
            style={{
              padding: '20px',
            }}
          >
            <StaticImage
              className=""
              style={{ width: '100%', border: 0, borderRadius: '10px' }}
              objectFit="contain"
              src="../images/sanos-gandrup-new.png"
              objectPosition="0% 0%"
              alt="studiesAndMe"
              loading="lazy"
              quality={100}
              placeholder="none"
            ></StaticImage>
          </div>
          <div className="col-md-6 col-12 mb-6">
            <h4 className="text-center text-md-start mb-4">{t('joinTheStudyAtYourConvenience.headline')}</h4>
            <p>
              {t('joinTheStudyAtYourConvenience.text2')}
              <br />
              {t('joinTheStudyAtYourConvenience.text3')}
              <br />
              {t('joinTheStudyAtYourConvenience.text4')}
              <br />
              <br />
              {t('joinTheStudyAtYourConvenience.text5')}
              <br />
              {t('joinTheStudyAtYourConvenience.text6')}
              <br />
              <br />
              {t('joinTheStudyAtYourConvenience.text7')}
              <br />
              {t('joinTheStudyAtYourConvenience.text8')}
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default ClinicsMap
