/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19/11/2022.
 */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import DropDown from '../assets/dropdown.svg'

import { Button } from './Button'

import gsap from 'gsap'

type Props = { scrollToSignup: () => void }

const orgFactsArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
const valueArr = ['1', '2', '3', '4', '5', '6']
const suitableArr = ['1', '2', '3', '4', '5']

export const FactsAboutTheStudy = ({ scrollToSignup }: Props) => {
  const [viewFull, setViewFull] = useState<boolean>(true)

  const { t } = useTranslation()

  const showMore = () => {
    setViewFull(!viewFull)
  }

  //const factsArr = viewFull ? orgFactsArr : orgFactsArr.slice(0, 4)
  const factsArr = ['1', '2']

  return (
    <div className="container-fluid pb-7" id="readmore" style={{ background: '#f3f2f0' }}>
      <Section
        headlineKey="wouldYouLikeToKnowMore.headline"
        itemKey="wouldYouLikeToKnowMore.text"
        subHeadlineKey="wouldYouLikeToKnowMore.subHeadline"
        arr={factsArr}
        viewFull={true}
      ></Section>

      <div className="row" style={{}}>
        {!viewFull ? (
          <div className="col-12 d-flex mt-4 justify-content-center">
            <Button type="primary" label="wouldYouLikeToKnowMore.btn" onClick={showMore}></Button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

const Section = ({ headlineKey, subHeadlineKey, itemKey, arr, viewFull }) => {
  const { t } = useTranslation()
  console.log('FactsAboutTheStudy > viewFull = ', viewFull)
  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 mt-md-6 mt-3">
          <h4 className=" mb-6 mt-4 text-center ">{t(headlineKey)} </h4>
        </div>
      </div>
      <div className="row" style={{}}>
        <div className="col-md-6 col-12 mx-auto d-flex align-items-center mb-3" style={{}}>
          <iframe
            style={{ aspectRatio: '16/9', width: '100%' }}
            allowFullScreen={true}
            src="https://www.youtube-nocookie.com/embed/riWiQaJEAT8?rel=0"
          ></iframe>
        </div>
        <div className="col-md-1 col-0 mx-auto"></div>
        <div className="col-md-5 col-12 mx-auto d-flex align-items-center">
          <div className="row overflow-hidden position-relative">
            {!viewFull ? (
              <div
                className="position-absolute w-100 h-75 noselect noclickable"
                style={{
                  left: 0,
                  bottom: 0,
                  zIndex: 10,
                  background: ' linear-gradient( rgba(243,242,240,0), rgba(243,242,240,1))',
                }}
              ></div>
            ) : null}

            {arr.map((item, index) => {
              const k = itemKey + item
              const head = subHeadlineKey + item

              const headline = t(head)

              const label = t(k)

              const isLast = index === arr.length - 1
              return (
                <>
                  <InfoItem key={index} headline={headline} item={label} isLast={false}></InfoItem>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const InfoItem = ({ item, headline, isLast }) => {
  const [state, setState] = useState(false)

  const ref = useRef()
  const handleRef = useRef()

  const clickker = () => {
    setState(!state)
  }

  useEffect(() => {
    gsap.killTweensOf(ref.current)
    gsap.set(ref.current, { y: state ? 10 : 0 })
    gsap.to(ref.current, 0.5, { y: state ? -10 : 10, height: state ? 'auto' : 0, ease: 'Expo.easeOut' })
    gsap.to(handleRef.current, 0.5, { opacity: state ? 0.5 : 1, rotate: state ? '-180' : 0, ease: 'Expo.easeOut' })
  }, [state])

  return (
    <div className="col-12 py-3 ">
      <div className="d-flex mb-3 justify-content-between pointer " onClick={clickker}>
        <p className="noselect d-block d-md-none fw-bold mb-2" style={{ maxWidth: '85%' }}>
          {headline}
        </p>
        <h5 className="noselect fw-bold d-none d-md-block">{headline}</h5>
        <div ref={handleRef} style={{ width: 25 }}>
          <DropDown></DropDown>
        </div>
      </div>

      <div ref={ref} className="overflow-hidden noselect" style={{ height: 0 }}>
        <p className="my-4 pe-4" dangerouslySetInnerHTML={{ __html: item }}></p>
      </div>
      {!isLast && <Line></Line>}
    </div>
  )
}

const Line = () => {
  return <div className="w-100 " style={{ background: 'black', height: 1, opacity: 0.4 }}></div>
}
