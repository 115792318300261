/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/06/2023.
 */
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const noop = () => {}
export const Button = ({ onClick = noop, type = 'bg-primary', label = 'text label ', className = '' }) => {
  const { t } = useTranslation()

  const cssClass =
    type === 'primary' ? 'btn btn-primary px-4 py-2 py-md-2 ' : 'btn bg-white text-primary px-4 py-2 py-md-2 '

  return (
    <button onClick={onClick} className={cssClass + ' ' + className}>
      {t(label)}
    </button>
  )
}
