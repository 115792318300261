/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/09/2022.
 */
import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SignUpForm } from './signUpForm/SignUpForm'
import { useForm } from 'react-hook-form'

import CheckMark from '../assets/check-mark.svg'

export const WhoAreWeLookingFor = ({ signupRef }) => {
  const { t } = useTranslation()
  const formHook = useForm()
  const { handleSubmit } = formHook

  return (
    <div className="container py-7 leaf">
      <div className="row" style={{}}>
        <div className="col-12 col-md-6 d-flex align-items-center ">
          {/* <h6>Facts about the study</h6> */}
          {/* What you need to know */}
          <div className="">
            <div className="d-flex align-items-center flex-column flex-md-row ">
              <h4 className="ms-0 ms-md-3 mt-4 mt-md-2 text-center text-md-start">{t('whoAreWeLookingFor.title')}</h4>
            </div>
            <h5 className="ms-0 ms-md-3 mt-4 mt-md-2 text-center text-md-start">
              {t('whoAreWeLookingFor.subHeadline')}
            </h5>
            <div className="ps-0 ps-md-4 mt-5 mb-6 mb-md-0">
              <CheckMarkWithText text={t('whoAreWeLookingFor.text1')} />
              <CheckMarkWithText text={t('whoAreWeLookingFor.text2')} />
              <CheckMarkWithText text={t('whoAreWeLookingFor.text3')} />
            </div>
            <div className="ps-0 ps-md-3 mt-5 mb-6 mb-md-0">
              <p>{t('whoAreWeLookingFor.or')}</p>
            </div>

            <div className="ps-0 ps-md-4 mt-5 mb-6 mb-md-0">
              <CheckMarkWithText text={t('whoAreWeLookingFor.text1b')} />
              <CheckMarkWithText text={t('whoAreWeLookingFor.text2b')} />
              <CheckMarkWithText text={t('whoAreWeLookingFor.text3b')} />
            </div>
            <div className="ps-0 mt-5 mb-6 mb-md-0">
              <p style={{ fontSize: '0.875rem' }}>{t('whoAreWeLookingFor.reimbursement')}</p>
            </div>
          </div>
        </div>

        <div className="col-0 col-md-1"></div>
        <div className="col-12 col-md-5 px-0">
          <div
            ref={signupRef}
            className=" p-3 "
            style={{ background: '#FFFFFF', boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.5)', borderRadius: '10px' }}
          >
            <div className="mt-3 mb-3">
              <h5 className="mb-2 mt-1 fw-bold">{t('form.headline')}</h5>
              <p className="mb-3">{t('form.subHeadline')}</p>
            </div>

            <SignUpForm formHook={formHook} handleSubmit={handleSubmit}></SignUpForm>
          </div>
        </div>
      </div>
    </div>
  )
}

function CheckMarkWithText({ text }: { text: string }) {
  return (
    <div className="d-flex flex-row align-items-center align-items-md-start">
      <div className="mb-0 mt-0 mt-md-1">
        <CheckMark></CheckMark>
      </div>
      <h5 className="ms-3 mb-2 h5-small pt-2 text-md-start"> {text}</h5>
    </div>
  )
}
